@charset "UTF-8";

@import "definitions";

// Default Variables
$slick-arrow-color: #bfbfbf !default;
$slick-dot-color: #fff !default;
$slick-dot-color-active: transparent !default;

.slide_image{
  img{
    width: 100%;
    height: auto;
  }
}
.slick-slide {
  min-height: initial;
  /* スライド画像下に謎の空白ができる対策 */
  > div{
    font-size: 0;
    line-height: 1;
  }
}

/* Arrows */
.slick-prev,
.slick-next {    
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  width: 20px;
  aspect-ratio: 1/1;
  font-size: 10px;
  line-height: 1;
  border: {
    left: 4px solid $slick-arrow-color;
    top: 4px solid $slick-arrow-color;
  }
  text-indent: -9999px;
}
.slick-prev {
  left: -25px;
  transform: translateY(-50%) rotate(-45deg);
}
.slick-next {
  right: -25px;
  transform: translateY(-50%) rotate(135deg);
}

/* Dots */
#conts{
  .slick-dots{
    margin: 0;
    li{
      margin: 0;
      padding: 0;
      text-indent: 0;
      &:before{
        display: none;
      }
    }
  }
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 10px;
  li {
    button {
      border: 0;
      display: block;
      width: 8px;
      font-size: 0;
      border: 1px solid #fff;
      aspect-ratio: 1/1;
      line-height: 1;
      background-color: $slick-dot-color;
      border-radius: 50%;
      text-indent: -9999px;
      cursor: pointer;
    }
    button:hover,
    &.slick-active button{
      background-color: $slick-dot-color-active;
      opacity: 1;
    }
  }
}

.slick-stop-play{
  a{
    display: block;
    width: 37px;
    height: auto !important;
    aspect-ratio: 1/1;
    background: url(../images/icon_pause.svg) no-repeat center center;
    background-size: contain;
    &.stopped{
      background-image: url(../images/icon_play.svg);
    }
  }
}
@media (hover: hover) {
  .slick-stop-play{
    a:hover{
      opacity: 0.7;
    }
  }
}


/* 各スライドの個別設定 */
#main_slider{
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  &.slick-initialized{
    display: block;
  }
  .slick-track{
    display: flow-root;
    height: 100%;
    &:after{
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 60;
      width: 100%;
      height: 120px;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
    }
  }
  .slick-list{
    overflow: hidden;
    position: relative;
    height: 100%;
    &:before, &:after{
      content: "";
      display: block;
      width: 300%;
      height: 100%;
      background-color: rgba($color: #000, $alpha: 0.3);
      position: absolute;
      top: 0;
      z-index: 1;
    }
    &:before{
      right: 100%;
    }
    &:after{
      left: 100%;
    }
  }
  .slick-prev,
  .slick-next {
    border-color: #fff;
    top: auto;
    bottom: 20px;
  }
  .slick-prev{
    left: 18px;
    transform: rotate(-45deg);
  }
  .slick-next {
    right: 18px;
    transform: rotate(135deg);
  }
  .slick-dots {
    bottom: 9px;
    justify-content: flex-end;
    padding-right: 65px;
    padding-bottom: 50px;
  }
  .slick-stop-play{
    a{
      background-image: url(../images/icon/icon_pause_white.svg);
      &.stopped{
        background-image: url(../images/icon/icon_play_white.svg);
      }
    }
  }
  .slick-slide {
    * {
      height: 100%;
    }
  }
  .slide_image{
    a{
      display: block;
    }
    img{
      object-fit: cover;
    }
  }
}


@media screen and (max-width: 768px) {

  #main_slider{
    width: 100%;
    .slick-dots {
      justify-content: center;
      padding: 0 15px;
    }
    .slick-stop-play{
      a{
        width: 34px;
      }
    }
    .slide_image{
      aspect-ratio: 16/9;
    }
  }

}

@media screen and (max-width: 540px) {

  .slick-prev,
  .slick-next {    
    width: 16px;
    border-width: 3px;
  }
  .slick-dots {
    li {
      button {
        width: 12px;
      }
    }
  }
  .slick-stop-play{
    a{
      width: 30px;
    }
  }

  #main_slider{
    .slick-prev,
    .slick-next {
      bottom: 17px;
    }
    .slick-prev{
      left: 15px;
    }
    .slick-next {
      right: 15px;
    }
    .slide_image{
      aspect-ratio: 3/2;
    }
  }

}